let data = {};
let mode = 3;
let Base = '';

const PAYPAL_CLIENT_ID = 'AW4Kxnz8WSBuQc0bDIpAve1J28_NVS-Kz85hnACqgmHiAnwm0ntuMWOTcRisfU90Qbi261klX8HxPG0B';

switch (mode) {
    case 1: Base = 'http://localhost'; break;
    case 2: Base = 'https://php7.teamlimonbyte.com/Sysge24/api/public'; break;
    case 3: Base = 'https://api.sysge24.com'; break;
    case 4: Base = 'http://154.38.182.171'; break;
    default: break;
}
switch(mode) {
    case 1: // dev local
        data = {
            BaseURI: `${Base}/Sysge24/api/public/api/`,
            BasePublic: `${Base}/Sysge24/api/public/`,
            BaseStorage: `${Base}/Sysge24/api/public/storage/`,
            BaseStore: 'http://php7.teamlimonbyte.com/Sysge24/public/',
            Socket: `${Base}:11020`,
            mode: mode,
            PAYPAL_CLIENT_ID
        }
        break;
    case 2: //Tuqueque
        data = {
            BaseURI: `${Base}/api/`,
            BasePublic: `${Base}/`,
            BaseStorage: `${Base}/storage/`,
            BaseStore: 'https://php7.teamlimonbyte.com/Sysge24/public/',
            Socket: `https://php7.teamlimonbyte.com:11021`,
            mode: mode,
            PAYPAL_CLIENT_ID
        }
        break;
    case 3: //SYSGE PROD
        data = {
            BaseURI: `${Base}/api/`,
            BasePublic: `${Base}/`,
            BaseStorage: `${Base}/storage/`,
            BaseStore: 'https://app.sysge24.com/',
            Socket: `https://web.sysge24.com:11021`,
            mode: mode,
            PAYPAL_CLIENT_ID
        }
        break;
    case 4: // Test
        data = {
            BaseURI: `${Base}/Sysge24/api/public/api/`,
            BasePublic: `${Base}/Sysge24/api/public/`,
            BaseStorage: `${Base}/Sysge24/api/public/storage/`,
            BaseStore: 'https://app.sysge24.com/',
            Socket: `${Base}:11020`,
            mode: mode,
            PAYPAL_CLIENT_ID
        }
        break;
    default:
        break;
}
export default data;