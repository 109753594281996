import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  //BrowserRouter,
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { RouteAuth } from "./components";
import { connect } from "react-redux";
import moment from "moment";
import { Constants, Globals, Scanner, axios } from "./utils";
import { Sidebar } from "./services";
//import { emit } from 'jetemit';
import "moment/locale/es";

// Routes
import Login from "./screens/login/login";
import Register from "./screens/register/register";
import Recover from "./screens/recover/recover";
import Home from './screens/home/home';
import EmptyHome from './screens/home/empty_home';
import RoleEnterprises from "./screens/roles/enterprises/role_enterprises";
import ProductProviders from "./screens/product-providers/product-providers";
import InventoryAdjustment from "./screens/inventory-adjustment/inventory";
import ProductCompound from "./screens/product-compound/product_compound";
import Enterprises from "./screens/enterprises/enterprises";
import Subcategory from "./screens/subcategory/subcategory";
import Currency from "./screens/currencies/currencies";
import Providers from "./screens/providers/providers";
import Warehouse from "./screens/warehouse/warehouse";
import Category from "./screens/category/category";
import Products from "./screens/products/products";
import Branches from "./screens/branches/branches";
import Clients from "./screens/clients/clients";
import Modules from "./screens/modules/modules";
import Brands from "./screens/brands/brands";
import Colors from "./screens/colors/color";
import Zones  from "./screens/zones/zone";
import Sizes from "./screens/sizes/size";
import Models from "./screens/models/models";
import Prices from "./screens/prices/prices";
import Order from "./screens/orders/orders";
import Users from "./screens/users/users";
import Conversions from "./screens/conversions/conversions";
import printerType from "./screens/printer-type/printer-type";
import Iva from "./screens/iva/iva";
import Requests from "./screens/requests/requests";
import Reports from "./screens/reports/reports";
import inventoryChanges from "./screens/inventory-changes/inventory-changes";
import ReportCommissions from "./screens/reports/commissions";
import ReportReturns from "./screens/reports/returns";
import ReportSellers from "./screens/reports/seller";
import ReportProviders from "./screens/reports/provider";
import TransferWarehouses from "./screens/transfer-warehouses/transfer-warehouses";
import DeliveryNotes from "./screens/delivery-notes/delivery-notes";
import Sales from "./screens/sales/sales";
import SalesReport from "./screens/requests/report";
import ReportSales from "./screens/reports/sales";
import SalesReportNew from "./screens/requests/new-request/new-request";
import SalesReportNewFiscal from "./screens/requests/new-request-fiscal/new-request";
import Dispatchs from "./screens/requests/dispatchs";
import Movements from "./screens/movements/movements";
import Payments from "./screens/payments/payments";
import Sellers from "./screens/sellers/sellers";
import Moderators from "./screens/moderators/moderators";
import Managers from "./screens/managers/managers";
import ReportPayment from "./screens/payments/report";
import ReportConsolidatedPayment from "./screens/payments/report-consolidated";
import Banks from "./screens/banks/banks";
import BankingTransactions from "./screens/banks/banking-transactions/banking-transactions";
import BankAccounts from "./screens/banks/accounts/bank_accounts";
import ExpensesIngress from "./screens/expenses-ingress/expenses-ingress";
import ExpensesReportPayments from "./screens/expenses-ingress/report-payments/report";
import DebtsToPay from "./screens/expenses-ingress/debts-to-pay/debts-to-pay";
import CashCount from "./screens/cash-count/cash-count";
import ReportCashCount from "./screens/reports/cash-count";
import Returns from "./screens/returns/returns";
import Exchange from "./screens/exchange/exchange";
import MonthlyEarnings from "./screens/monthly-earnings/monthly_earnings";
import Quotes from "./screens/quotes/quotes";
import Whatsapp from "./screens/whatsapp/whatsapp";
import VisitPlanner from "./screens/visit-planner/visit-planner";
import VisitPlannerCreate from "./screens/visit-planner/visit-planner-create";
import VisitUnplanned from "./screens/visit-planner/visit-unplanned";
import VisitUnplannedDetails from "./screens/visit-planner/visit-unplanned-details";
import IncomeExpensesExtra from "./screens/incomesExpensesExtra/incomeExpensesExtra";
import Access from "./screens/access/access";
import Docu from "./screens/docu/docu";
import Dashboard from "./screens/dashboard/dashboard";
import MyProfileAccount from "./screens/myProfileAccount/myProfileAccount";
import Commissions from "./screens/commissions/commissions";
import CommissionsPayments from "./screens/commissions/payments";
import Purchases from "./screens/purchases/purchases";
import paymentMethodAdmin from "./screens/paymentMethodAdmin/paymentMethodAdmin";
import AccountsToPayPurchases from "./screens/purchases/accountsToPay";
import AccountsToPayReport from "./screens/reports/accounts_to_pay";
import ReportVisitPlanner from "./screens/reports/visit-planner";
import Autosale from "./screens/visit-planner/autosale";
import ReportAutosale from "./screens/reports/autosale";
import ReportFiscal from "./screens/reports/reportfiscal";
import ReportProductMostSold from "./screens/reports/productsMostSold";


// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import $ from "jquery";

class _Container extends React.Component {
  constructor(props) {
    super(props);

    moment.locale("es");

    this.state = {
      tooltip: false
    };

    Scanner.init();
  }

  abortController = new AbortController();
  
  componentDidMount() {
    this.checkRoutesUpdate();
    window.addEventListener('beforeunload', (event) => {
      // No cancelar el evento ni establecer un mensaje personalizado
      // event.preventDefault();
      // event.returnValue = '';
    });
    $(window).focus(() => {
      if (!this.state.tooltip) Globals.hideTooltip();
    });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  checkRoutesUpdate = async () => {
    const { sidebar, user } = this.props;
    if (!sidebar.length || !user?.id) return;

    const menuDates = [];
    const moduleDates = sidebar.map(sb => {
      if (!!sb.menus?.updated_at)
        menuDates.push(moment(sb.menus?.updated_at));

      return moment(sb.updated_at);
    });

    const menuMaxDate = moment.max(menuDates).format('YYYY-MM-DD HH:mm:ss');
    const moduleMaxDate = moment.max(moduleDates).format('YYYY-MM-DD HH:mm:ss');

    try {
      const res = await Sidebar.checkModuleUpdate({ menuMaxDate, moduleMaxDate });
      if (!res?.hasUpdate) return;

      const modules = await Sidebar.getModuleByUser(user.id);

      this.props.dispatch({
        type: "SET_SIDEBAR",
        payload: modules,
      });

    } catch (error) {
      console.log('App -> checkRoutesUpdate -> Catch', error)
    }
  }

  render() {
    const { sidebar, user } = this.props;

    const currentRoute = window.location.hash.substring(1);
    let route = "/login";

    if (user) {
      if (sidebar.length > 0 && currentRoute !== '/') {
        route = currentRoute;
        // if(sidebar[0].path){
        //   route = sidebar[0].path;
        // }
        // else{
        //   route = '/brands';
        // }
      }
    }

    return (
      <Router>
        <Switch>
          <RouteAuth exact path="/" component={EmptyHome} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/recover" component={Recover} />
          <Route exact path="/home" component={Home} />

          {hasPermission(sidebar, "/dashboard") && (
            <RouteAuth exact path="/dashboard" component={Dashboard} />
          )}

          {
            hasPermission(sidebar, "/profile-business") && (
              this.props.user.level_id === Constants.ROLE_ADMIN
                ? <RouteAuth exact path="/profile-business" component={MyProfileAccount} />
                : (this.props.user.level_id === Constants.ROLE_MODERATOR || this.props.user.level_id === Constants.ROLE_MANAGER)
                  ? <RouteAuth exact path="/profile-business" component={MyProfileAccount} />
                  : null
            )
          }

          {hasPermission(sidebar, "/users") && (
            <RouteAuth exact path="/users" component={Users} />
          )}

          {hasPermission(sidebar, "/enterprises") && (
            <RouteAuth exact path="/enterprises" component={Enterprises} />
          )}

          {hasPermission(sidebar, "/warehouses") && (
            <RouteAuth exact path="/warehouses" component={Warehouse} />
          )}

          {hasPermission(sidebar, "/currencies") && (
            <RouteAuth exact path="/currencies" component={Currency} />
          )}

          {hasPermission(sidebar, "/categories") && (
            <RouteAuth exact path="/categories" component={Category} />
          )}

          {hasPermission(sidebar, "/orders") && (
            <RouteAuth exact path="/orders" component={Order} />
          )}

          {hasPermission(sidebar, "/categories") && (
            <RouteAuth
              exact
              path="/subcategories/:category_id"
              component={props => <Subcategory {...props} />}
            />
          )}

          {hasPermission(sidebar, "/products") && (
            <RouteAuth
              exact
              path="/products/providers/:product_id"
              component={props => <ProductProviders {...props} />}
            />
          )}

          {hasPermission(sidebar, "/users") && (
            <RouteAuth exact path="/users" component={Users} />
          )}

          {hasPermission(sidebar, "/roles_enterprises") && (
            <RouteAuth
              exact
              path="/roles_enterprises"
              component={RoleEnterprises}
            />
          )}

          {hasPermission(sidebar, "/brands") && (
            <RouteAuth exact path="/brands" component={Brands} />
          )}

          {hasPermission(sidebar, "/brands") && (
            <RouteAuth
              exact
              path="/models/:brand_id"
              component={props => <Models {...props} />}
            />
          )}

          {hasPermission(sidebar, "/zones") && (
            <RouteAuth exact path="/zones" component={Zones} />
          )}
          {hasPermission(sidebar, "/sizes") && (
            <RouteAuth exact path="/sizes" component={Sizes} />
          )}
          {hasPermission(sidebar, "/colors") && (
            <RouteAuth exact path="/colors" component={Colors} />
          )}

          {hasPermission(sidebar, "/prices") && (
            <RouteAuth exact path="/prices" component={Prices} />
          )}

          {hasPermission(sidebar, "/clients") && (
            <RouteAuth exact path="/clients" component={Clients} />
          )}

          {hasPermission(sidebar, "/modules") && (
            <RouteAuth exact path="/modules" component={Modules} />
          )}

          {hasPermission(sidebar, "/products") && (
            <RouteAuth exact path="/products" component={Products} />
          )}

          {hasPermission(sidebar, "/branches") && (
            <RouteAuth exact path="/branches" component={Branches} />
          )}

          {hasPermission(sidebar, "/providers") && (
            <RouteAuth exact path="/providers" component={Providers} />
          )}

          {hasPermission(sidebar, "/sellers") && (
            <RouteAuth exact path="/sellers" component={Sellers} />
          )}

          {hasPermission(sidebar, "/moderators") && ( 
            <RouteAuth exact path="/moderators" component={Moderators} />
          )}

          {hasPermission(sidebar, "/managers") && (
            <RouteAuth exact path="/managers" component={Managers} />
          )}

          {hasPermission(sidebar, "/products-compounds") && (
            <RouteAuth
              exact
              path="/products-compounds"
              component={ProductCompound}
            />
          )}

          {hasPermission(sidebar, "/inventory-adjustment") && (
            <RouteAuth
              exact
              path="/inventory-adjustment"
              component={InventoryAdjustment}
            />
          )}

          {hasPermission(sidebar, "/prices") && (
            <RouteAuth exact path="/prices" component={Prices} />
          )}

          {hasPermission(sidebar, "/conversions") && (
            <RouteAuth exact path="/conversions" component={Conversions} />
          )}

          {/*{hasPermission(sidebar, "/printer-type") && (
            <RouteAuth exact path="/printer-type" component={printerType} />
          )}*/}

          {/*{hasPermission(sidebar, "/iva") && (
            <RouteAuth exact path="/iva" component={Iva} />
          )}*/}

          {/*{hasPermission(sidebar, "/apitoken-docu") && (
            <RouteAuth exact path="/apitoken-docu" component={Docu} />
          )}*/}

          {hasPermission(sidebar, "/apitoken-access") && (
            <RouteAuth exact path="/apitoken-access" component={Access} />
          )}

          {/*{hasPermission(sidebar, "/payment-methods-admin") && (
            <RouteAuth exact path="/payment-methods-admin" component={paymentMethodAdmin} />
          )}*/}

          {hasPermission(sidebar, "/incomeExpensesExtra") && (
            <RouteAuth exact path="/incomeExpensesExtra" component={IncomeExpensesExtra} />
          )}

          {hasPermission(sidebar, "/requests") && (
            <RouteAuth exact path="/requests" component={Requests} />
          )}

          {hasPermission(sidebar, "/report-inventory") && (
            <RouteAuth exact path="/report-inventory" component={Reports} />
          )}

          {hasPermission(sidebar, "/report-inventory-changes") && (
            <RouteAuth exact path="/report-inventory-changes" component={inventoryChanges} />
          )}

          {hasPermission(sidebar, "/report-sellers") && (
            <RouteAuth exact path="/report-sellers" component={ReportSellers} />
          )}

          {hasPermission(sidebar, "/report-mostSoldProducts") && (
            <RouteAuth exact path="/report-mostSoldProducts" component={ReportProductMostSold} />
          )}

          {hasPermission(sidebar, "/report-commissions") && (
            <RouteAuth exact path="/report-commissions" component={ReportCommissions} />
          )}

          {hasPermission(sidebar, "/report-returns") && (
            <RouteAuth exact path="/report-returns" component={ReportReturns} />
          )}

          {hasPermission(sidebar, "/report-providers") && (
            <RouteAuth exact path="/report-providers" component={ReportProviders} />
          )}

          {hasPermission(sidebar, "/report-cash-count") && (
            <RouteAuth exact path="/report-cash-count" component={ReportCashCount} />
          )}

          {hasPermission(sidebar, "/report-autosale") && (
            <RouteAuth exact path="/report-autosale" component={ReportAutosale} />
          )}

          {hasPermission(sidebar, "/whatsapp") && (
            <RouteAuth exact path="/whatsapp" component={Whatsapp} />
          )}

          {hasPermission(sidebar, "/visit-planner") && (
              <RouteAuth exact path="/visit-planner" component={VisitPlanner} />
          )}

          {hasPermission(sidebar, "/visit-planner") && (
              <RouteAuth exact path="/visit-planner/create" component={VisitPlannerCreate} />
          )}

          {hasPermission(sidebar, "/visit-planner") && (
              <RouteAuth exact path="/visit-planner/create/:id" component={VisitPlannerCreate} />
          )}

          {hasPermission(sidebar, "/visit-unplanned") && (
              <RouteAuth exact path="/visit-unplanned" component={VisitUnplanned} />
          )}

          {hasPermission(sidebar, "/visit-unplanned") && (
              <RouteAuth exact path="/visit-unplanned/details" component={VisitUnplannedDetails} />
          )}

          {hasPermission(sidebar, "/autosale") && (
              <RouteAuth exact path="/autosale" component={Autosale} />
          )}

          {hasPermission(sidebar, "/warehouse-transfers") && (
            <RouteAuth
              exact
              path="/warehouse-transfers"
              component={TransferWarehouses}
            />
          )}

          {hasPermission(sidebar, "/delivery-notes") && (
            <RouteAuth exact path="/delivery-notes" component={DeliveryNotes} />
          )}
          {hasPermission(sidebar, "/sales") && (
            <RouteAuth exact path="/sales" component={Sales} />
          )}

          {hasPermission(sidebar, "/report-sale") && (
            <RouteAuth exact path="/report-sale" component={SalesReport} />
          )}

          {hasPermission(sidebar, "/report-sales") && (
            <RouteAuth exact path="/report-sales" component={ReportSales} />
          )}

          {hasPermission(sidebar, "/dispatchs") && (
            <RouteAuth exact path="/dispatchs" component={Dispatchs} />
          )}

          {hasPermission(sidebar, "/report-sale") && (
            <RouteAuth exact path="/report-sale/create" component={SalesReportNew} />
          )}

          {hasPermission(sidebar, "/report-sale") && (
            <RouteAuth exact path="/report-sale/fiscal/create" component={SalesReportNewFiscal} />
          )}

          {hasPermission(sidebar, "/report-movement") && (
            <RouteAuth exact path="/report-movement" component={Movements} />
          )}

          {hasPermission(sidebar, "/accounts-receivable") && (
            <RouteAuth exact path="/accounts-receivable" component={Payments} />
          )}

          {hasPermission(sidebar, "/accounts-pay-purchases") && (
            <RouteAuth exact path="/accounts-pay-purchases" component={AccountsToPayPurchases} />
          )}

          {hasPermission(sidebar, "/report-accounts-pay") && (
            <RouteAuth exact path="/report-accounts-pay" component={AccountsToPayReport} />
          )}

          {hasPermission(sidebar, "/quotes") && (
            <RouteAuth exact path="/quotes" component={Quotes} />
          )}

          {/* {hasPermission(sidebar, "/sellers") && (
            <RouteAuth exact path="/sellers" component={Sellers} />
          )} */}

          {hasPermission(sidebar, "/returns") && (
            <RouteAuth exact path="/returns" component={Returns} />
          )}

          {hasPermission(sidebar, "/report-payment") && (
            <RouteAuth exact path="/report-payment" component={ReportPayment} />
          )}

          {hasPermission(sidebar, "/report-consolidated") && (
            <RouteAuth exact path="/report-consolidated" component={ReportConsolidatedPayment} />
          )}

          {hasPermission(sidebar, "/banks") && (
            <RouteAuth exact path="/banks" component={Banks} />
          )}

          {hasPermission(sidebar, "/banks") && (
            <RouteAuth
              exact
              path="/bank-accounts/:bank_id"
              component={props => <BankAccounts {...props} />}
            />
          )}

          {hasPermission(sidebar, "/expenses-ingress") && (
            <RouteAuth exact path="/expenses-ingress" component={ExpensesIngress} />
          )}

          {hasPermission(sidebar, "/expenses-ingress") && (
            <RouteAuth exact path="/expenses-payments-report" component={ExpensesReportPayments} />
          )}

          {hasPermission(sidebar, "/debts-to-pay") && (
            <RouteAuth exact path="/debts-to-pay" component={DebtsToPay} />
          )}

          {hasPermission(sidebar, "/banking-transactions") && (
            <RouteAuth exact path="/banking-transactions" component={BankingTransactions} />
          )}

          {hasPermission(sidebar, "/cash-count") && (
            <RouteAuth exact path="/cash-count" component={CashCount} />
          )}

          {hasPermission(sidebar, "/report-visit-planners") && (
              <RouteAuth exact path="/report-visit-planners" component={ReportVisitPlanner} />
          )}

          {hasPermission(sidebar, "/monthly-earnings") && (
            <RouteAuth exact path="/monthly-earnings" component={MonthlyEarnings} />
          )}

          {hasPermission(sidebar, "/commissions") && (
            <RouteAuth exact path="/commissions" component={Commissions} />
          )}

          {hasPermission(sidebar, "/commissions") && (
            <RouteAuth exact path="/commissions/payments" component={CommissionsPayments} />
          )}

          {hasPermission(sidebar, "/purchases") && (
            <RouteAuth exact path="/purchases" component={Purchases} />
          )}

          <RouteAuth exact path="/exchange" component={Exchange} />

          <RouteAuth exact path="/fiscalreport" component={ReportFiscal} />

          <Route path="*">
            <Redirect to={route} />
          </Route>
        </Switch>
        <Redirect path="*" to={route} />
      </Router>
    );
  }
}

function hasPermission(sidebar, role) {
  return !!sidebar.find(({ path }) => path === role);
}

const NoMatch = () => {
  return (
    <div>
      <h3>
        Pagina no encontrada<code>404</code>
      </h3>
    </div>
  );
};

const Container = connect(state => {
  return {
    support: state.support,
    user: state.user,
    sidebar: state.sidebar
  };
})(_Container);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Container />
    </PersistGate>
  </Provider>
);

export default App;
